<i18n>
ru:
  option: '{num} опция'
ua:
  option: '{num} опція'
us:
  option: '{num} option'
</i18n>

<template>
  <div
    v-if="
      onlySliderVersion ||
      (options as T[]).length === 1 ||
      (isDesktopVersion &&
        (options as T[]).length > 0 &&
        (options as T[]).length < (threshold as number))
    "
    ref="toggleSwitcher"
    v-on-resize="{ onResize: updateSwitches, once: false }"
    :class="[
      !stringIsNullOrWhitespace(theme as string)
        ? `v-arora-option-slider-${theme}`
        : 'v-arora-option-slider-default',
      disabled ? 'disabled' : ''
    ]"
    :data-test-id="dataTestId && `${dataTestId}-slider`"
    :style="`--element:${elementWidth}px; --items: ${(options as T[]).length}; --height: ${height}px; --deviation: ${deviation}px;`"
    class="v-arora-option-slider"
  >
    <div
      v-for="(item, index) in options"
      :id="`${uid}-${index.toString()}`"
      :key="`${uid}-${index.toString()}`"
      :aria-label="
        translate('AroraOptionSlider.option', {
          num: parseInt(index.toString()) + 1
        })
      "
      :class="{
        'v-active': index === pickedIndex,
        disabled: !indexEnabled(item),
        'v-pointer-events-none': (options as T[]).length === 1
      }"
      :data-test-id="
        dataTestId &&
        `${dataTestId}-slider-option${index === pickedIndex ? '--selected' : ''}`
      "
      class="v-arora-option-slider__item v-text-center"
      @click="async () => (picked = item as T)"
    >
      <slot
        :value="item"
        name="option"
      />
      <icon-old-general-stop
        v-if="!indexEnabled(item)"
        class="v-arora-option-slider--stop-icon"
      />
    </div>
    <div
      :id="`${uid}-selected`"
      :style="{ transform: `translateX(${elementWidth * pickedIndex}px)` }"
      class="v-arora-option-slider__selected"
    />
  </div>
  <div
    v-else-if="(options as T[]).length > 0"
    class="v-w-100 v-mb-sm"
  >
    <common-dropdown-menu
      :data-test-id="dataTestId"
      :disabled="disabled"
      :items="options as T[]"
      :label="label"
      close-on-click
    >
      <template #title>
        <slot
          :value="picked"
          name="option"
        />
      </template>
      <template #item="item">
        <div
          :class="{
            'v-selected-in-dropdown': getDropdownSelectedOption(
              options as T[],
              item as T
            ),
            disabled: !indexEnabled(item)
          }"
          :data-test-id="
            dataTestId &&
            `${dataTestId}-dropdown-single${getDropdownSelectedOption(options as T[], item as T) ? '--selected' : ''}`
          "
          class="v-ml-sm v-mb-xs v-pointer"
          @click="async () => (picked = item as T)"
        >
          <slot
            :value="item"
            name="option"
          />
          <span
            v-if="!indexEnabled(item) && !stringIsNullOrWhitespace(disabledText)"
            class="v-ml-xxs"
            v-html="disabledText"
          />
        </div>
      </template>
      <template #index="item: { index: number }">
        <div
          :class="{
            'v-selected-in-dropdown': pickedIndex === item.index,
            disabled: !indexEnabled((options as T[])[item.index])
          }"
          :data-test-id="
            dataTestId &&
            `${dataTestId}-dropdown-single${pickedIndex === item.index ? '--selected' : ''}`
          "
          class="v-ml-sm v-mb-xs v-pointer"
          @click="async () => (picked = (options as T[])[item.index])"
        >
          <slot
            :value="(options as T[])[item.index]"
            name="option"
          />
          <span
            v-if="
              !indexEnabled((options as T[])[item.index]) &&
              !stringIsNullOrWhitespace(disabledText)
            "
            class="v-ml-xxs"
            v-html="disabledText"
          />
        </div>
      </template>
    </common-dropdown-menu>
  </div>
</template>

<script generic="T" lang="ts" setup>
import type { OptionTheme } from '~types/props'

import { useCommon, useWindowSize, type VElement, vOnResize } from '@arora/common'

const props = withDefaults(
  defineProps<
    VElement & {
      selected: T
      options: T[]
      threshold?: number
      height?: number
      theme?: OptionTheme | null
      indexEnabled?: (t: T) => boolean
      deviation?: number
      disabledText?: string
      onlySliderVersion?: boolean
    }
  >(),
  {
    deviation: 3,
    disabled: false,
    disabledText: '',
    height: 38,
    indexEnabled: (_t: T) => true,
    theme: null,
    threshold: 3
  }
)
const emit = defineEmits(['update:selected'])

const { translate } = useI18nSanitized()
const { getStyleWidth, stringIsNullOrWhitespace } = useCommon()
const { isDesktopVersion } = useWindowSize()

const { deviation, indexEnabled, options, selected, threshold } = toRefs(props)
const picked = computed<T>({
  get() {
    if (selected.value === undefined) {
      emit('update:selected', options.value[0])
      return options.value[0]
    }
    return selected.value
  },
  set(value) {
    emit('update:selected', value)
    updateIndex(value)
  }
})

const pickedIndex = ref<number>(0)
const uid = useId()
const elementWidth = ref<number>(0)
const toggleSwitcher = ref<HTMLDivElement>()

function updateIndex(value: T): void {
  let selectedIndex = 0
  for (let index = 0; index < options.value.length; index++) {
    if (value && typeof value === 'object' && 'ID' in value) {
      if (options.value[index].ID === value.ID) selectedIndex = index
    } else if (options.value[index] === value) selectedIndex = index
  }
  pickedIndex.value = selectedIndex
}

function updateSwitches(): void {
  if (toggleSwitcher.value) {
    const localWidth = getStyleWidth(toggleSwitcher.value)
    if (localWidth === 0) {
      setTimeout(() => updateSwitches(), 300)
      return
    }
    elementWidth.value = Math.floor(
      (localWidth - deviation.value * 2) / options.value.length
    )
  }
}

function getDropdownSelectedOption<T>(options: T[], item: T): boolean {
  if (typeof item === 'object' && item && 'key' in item)
    return item.key === pickedIndex.value
  return options.indexOf(item) === pickedIndex.value
}

onMounted(() => {
  updateSwitches()
  updateIndex(selected.value)
})
onUpdated(() => {
  updateSwitches()
  updateIndex(selected.value)
})
watch(
  () => selected.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) updateIndex(newValue)
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

$deviation: var(--deviation, 3px);
$element: var(--element, 100%);
$height: var(--height, 34px);
$items: var(--items, 1);

.v-arora-option-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: variables.$BorderRadiusButton;
  padding: $deviation;
  position: relative;

  &--stop-icon {
    width: 1rem;
    height: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: transparent;
    z-index: 2;
    border-radius: variables.$BorderRadiusButton;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border: none;
    font-size: 0.9rem;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: $element;
    max-width: $element;
    height: $height;

    svg {
      transition-delay: 0.2s;
    }

    &:focus,
    &:active,
    &:visited {
      outline: none;
      box-shadow: none;
      border-radius: variables.$BorderRadiusButton;
    }

    &:hover {
      outline: none;
    }
  }

  &__selected {
    height: $height;
    width: calc($element - ($deviation / 2));
    position: absolute;
    z-index: 1;
    top: $deviation;
    border-radius: calc(variables.$BorderRadiusButton - $deviation);
    background-color: variables.$OptionsBackground;
    transition: transform 0.4s ease;
  }

  &-default {
    border: 1px solid variables.$BorderColor;
    margin: 0 0 4px;
  }
}

.v-selected-in-dropdown {
  color: variables.$PrimaryBackgroundColor;
  @include mixins.trans;

  &:hover {
    color: variables.$PrimaryBackgroundColorDarken35;
  }
}
</style>
